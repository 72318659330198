.navbar-collapse{
    justify-content: flex-end;
  }
.navbar{
  justify-content: flex-end !important;
}
.nav-link{
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.125rem;
}
.navbar-nav{
  text-align: center;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
  color:#B21011 !important;
}
