:root {
  --red: #b20e10; /*rojo ef */
  --redDark: #9e0b0b; /*rojo oscuro*/
  --green: #2c5b64; /*verde ef */
  --negro: #000000;
  --ubuntu: "Ubuntu", sans-serif;
  --barlow: "Barlow Condensed", sans-serif;
}
.color-red {
  color: var(--red);
}
body {
  font-family: var(--barlow);
  background-color: #fff;
}
.scroll-to-top{
  z-index: 5;
}
a{
  text-decoration: none;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--green);
}
a:hover{
  color:var(--red);
}
h2 {
  font-family: var(--ubuntu);
  font-size: 3rem;
  color: var(--red);
}
h3 {
  font-family: var(--ubuntu);
  font-size: 2rem;
  font-weight: 400;
  color: var(--green);
}
h3.porque {
  font-size: 2.5rem;
}
.btn-primary {
  font-size: 1.4rem;
  border-radius: 0;
  border: 1px solid var(--green);
  color: var(--green);
  background-color: #fff;
  font-weight: 500;
  padding: 0.3rem 1.5rem;
}
.btn-primary:hover {
  background-color: var(--green);
  color: #fff;
}
.btn-secondary {
  font-size: 1.4rem;
  border-radius: 0;
  color:#fff;
  background-color:var(--red);
  font-weight: 500;
  padding: 0.3rem 1.5rem;
  border: none;
}
.btn-secondary:hover {
  background-color: var(--green);
  color: #fff;
  border: none;
}
.pb-personalizado {
  padding-bottom: 8rem;
}
.font-small{
  font-size: 1rem;
  line-height: 1.2rem;
}
.fs-4{
  font-weight: 1.2rem;
  line-height: 1.5rem;
}
/*header*/
header {
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  min-height: 60px;
  position: sticky !important;
  top:0;
  z-index: 10;
}
.ball {
  position: absolute;
  top: 10px;
  z-index: 1;
}
/*video*/
.box__video {
  max-width: 890px;
  height: auto;
  text-align: center;
  overflow: hidden;
  padding: 0;
  outline: 5px solid #fff;
  outline-offset: -5px;
}

/*cards*/
.bg__box-cards {
  background-image: linear-gradient(
    to bottom,
    #fff 0%,
    #fff 25%,
    var(--red) 25%,
    var(--red) 50%,
    #fff 50%
  );
}
.box__card {
  font-family: var(--barlow);
  background-color: #e9e8e9;
  background-image: url("../public/pico.svg");
  background-repeat: no-repeat;
  background-position: center top;
  padding: 1.5rem 1rem 1rem 1rem;
  min-height: 200px;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

.destacado {
  color: var(--redDark);
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 2.5rem;
}
/*sections*/
h4 {
  font-family: var(--ubuntu);
  color: #f0f4f5;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2rem;
  margin: 0;
}
@media (min-width: 576px) {
  h4 {
    font-size: 3.5rem;
    line-height: 2.6rem;
  }
}
@media (min-width: 768px) {
  h4 {
    font-size: 5rem;
    line-height: 3.5rem;
  }
}
.bg__section-left {
  background-image: linear-gradient(
    to right,
    #f0f4f5 0%,
    #f0f4f5 50%,
    #fff 50%
  );
  overflow: hidden;
}
.bg__section-right {
  background-image: linear-gradient(to left, #f0f4f5 0%, #f0f4f5 50%, #fff 50%);
  overflow: hidden;
}
.bg__cont {
  background-color: #f0f4f5;
}
.text__p {
  font-size: 1.8rem;
  line-height: 2rem;
}
/*notoriedad*/
.btn__notoriedad {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #fff;
  border: none;
  padding: 1rem;
  text-align: center;
  margin-right:0;
  transition: all 0.5s ease-in-out;
  z-index: 2;
}
.btn__notoriedad:hover {
  transform: translatey(1rem);
}
.pico__btn{
  transform: rotate(90deg);
  width: 5px;
}
@media (min-width: 992px) {
  .btn__notoriedad {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: #fff;
    border: none;
    padding: 1rem;
    text-align: right;
    margin-right: -30px;
    transition: all 0.5s ease-in-out;
    z-index: 2;
  }
  .btn__notoriedad:hover {
    transform: translateX(1rem);
  }
  .pico__btn{
    transform: rotate(0deg);
    width: 10px;
  }
}
.btn__impresa {
  background-color: #098d9b;
  width: 250px;
}
.btn__web {
  background-color: #21757e;
  width: 200px;
}
.btn__otros {
  background-color: #65bec8;
  width: 210px;
}

.box__graph {
  position: relative;
  right: 0;
  z-index: 0;
  text-align: center;
}
.close {
  border: none;
  background-color: transparent;
  position: absolute;
  margin:auto;
  right: 0;
  left: 0;
  width: 20px;
  bottom:-20px;
  cursor: pointer;
  transition: right 0.5s ease-in-out;
  z-index: 1;
  transform: rotate(90deg);
}
.close:hover {
  right:0;

}
@media (min-width: 992px) {
  .box__graph {
    position: absolute;
    right: -85%;
    width: 80%;
    z-index: 0;
  }
  .close {
    border: none;
    background-color: transparent;
    position: absolute;
    left:auto;
    right: 15px;
    width: 20px;
    top: 42%;
    bottom:auto;
    cursor: pointer;
    transition: right 0.5s ease-in-out;
    z-index: 1;
    transform: rotate(0deg);
  }
  .close:hover {
    right: 23px;
  }
}


.margin-negative {
  margin-top: -250px;
}
/*revista*/
.box__card-revista {
  font-family: var(--barlow);
  background-color: #fff;

  min-height: 250px;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 400;
  text-align: center;
  width: 100%;
  border: 1px solid #eaeff0;
}
.rev__number {
  padding: 1rem;
  background-color: #9e0b0b;
  color: #fff;
  width: 100%;
  font-weight: 600;
  font-size: 1.8rem;
}
.rev__content {
  background-image: url("https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/pico.svg");
  background-repeat: no-repeat;
  background-position: center 55px;
  /* padding: 1.5rem 1rem 1rem 1rem; */
  width: 100%;
  word-wrap: break-word;
}
.rev__content-mes {
  font-size: 2rem;
  font-weight: 300;
}
.rev__content-text {
  font-size: 1.2rem;
  line-height: 1.3rem;
  text-align: left;
}
h6 {
  font-size: 1.2rem;
  color: var(--red);
  font-weight: 700;
}
.rev__box {
  background-color: #f0f4f5;
  color: var(--green);
  padding: 0.5rem;
}
.rev__destacado {
  width: 100%;
  background-color: #6db0b7;
  background-image: url("https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/pico-white.svg");
  background-repeat: no-repeat;
  background-position: center -1px;
  padding: 2rem 1rem 1rem 1rem;
  min-height: 120px;
  color: #fff;
}
.rev__day {
  font-family: var(--ubuntu);
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 700;
}
/*slider slick*/
.min__card-magazine{
  min-height: 620px;
}
.slick-prev:before {
  content: "←";
  color: #ccc !important;
  font-size: 2rem !important;
}
.slick-next:before {
  content: "→";
  color: #ccc !important;
  font-size: 2rem !important;
}
.slick-next {
  right: 25px !important;
  position: absolute;
  z-index: 2;
}
.slick-prev {
  left: -5px !important;
  position: absolute;
  z-index: 2;
}
/*especiales*/
.box__especiales {
  min-height: 300px;
  width: 100%;
  background-color: var(--red);
  background-image: url("https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/fondo-red.jpg");
  color: #fff;
  font-weight: 700;
  font-size: 3rem;
  padding: 2rem;
}
.e__box {
  width: 50%;
  height: 50px;
  text-align: center;
}
.e__box-info {
  width: 100%;
  font-size: 3rem;
  text-align: center;
}
.e__box-video {
  width: 100%;
  text-align: start;
  font-size: 2rem;
}
.e__box-dias {
  width: 100%;
  text-align: end;
  padding-left: 1rem;
  font-size: 3.5rem;
}
.e__box-podcast {
  width: 100%;
  font-size: 4rem;
}
.e__box-form {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.e__box-product {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}
.e__box-product {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
}
.e__box-guias {
  width: 100%;
  text-align: center;
  font-size: 4rem;
}
/*relevancia*/
.mobil {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 260px;
}
.farmaceutico {
  margin-top: 150px;
}

/*form*/
.form-floating > label {
  padding: 1rem 2rem;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem;
}

/*modulos*/
.modal-header{
  border:none;
}
.modal-footer{
  border:none;
}
.modal{
  --bs-modal-width:700px;
}
.p__modal{
  font-size: 1.3rem;
  line-height: 1.5rem;
}
.box__green{
  background-color: #2D5C65;
  padding: .5rem .5rem 0rem .5rem;
  color:#fff;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.5rem;
  font-weight: 700;
  
}
.arrow__green{
  margin-bottom: -40px;
}
.box__tematic{
  border: 1px solid #ccc;
  padding: 1.5rem;
  font-size: 1.5rem;
  margin-top: 15px;
}
ul.list__tematic{
  list-style-image: url("../public/check.svg")

}
hr{
  width: 10%;
  margin:auto;
}
.w-img{
  width: 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .border-left{
    border-right: 1px solid var(--green);
  }
}
li::marker{
  color:var(--red);
}
